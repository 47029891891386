<template>
  <div v-if="isMounted === false" class="row">
    <div class="col-12 col-lg-6 offset-lg-3 col-xl-4 offset-xl-4">
      <div class="loading-container">
        <div class="loading-block">
          <div class="blockui ml-auto mr-auto w-100">
            <span class="float-left">Gathering data...</span>
            <span class="spinner spinner-primary ml-auto" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="row row-cols-1">
    <!-- begin::Permission groups -->
    <template v-if="permissionGroups && permissionGroups.length">
      <div v-for="(permissionGroup, idx) in permissionGroups" :key="`permission_group.${idx}.${permissionGroup.id}`" class="col">
        <label class="option cursor-pointer">
          <span class="option-control">
            <span class="radio">
              <input
                type="radio"
                v-model.trim="$v.companyPermissionID.$model"
                name="permission_group"
                :value="permissionGroup.id"
                :disabled="isDisabled"
              >
              <span />
            </span>
          </span>
          <span class="option-label">
            <span class="option-head">
              <span class="option-title">{{ $tu(`permission_groups.type.${permissionGroup.id}.name`) || permissionGroup.id }}</span>
            </span>
            <span v-if="$te(`permission_groups.type.${permissionGroup.id}.description`)" class="option-body">{{ $t(`permission_groups.type.${permissionGroup.id}.description`) }}</span>
          </span>
        </label>
      </div>
    </template>
    <!-- end::Permission groups -->

    <!-- begin::Alert error -->
    <template v-else-if="isErrorActive">
      <error-translation-alert
        ref="errorTranslationAlert"
        :error="activeError"
      />
    </template>
    <!-- end::Alert error -->

    <!-- begin::Alert -->
    <template v-else>
      <div class="col-12 alert alert-custom alert-light-info my-0" role="alert">
        <div class="alert-icon">
          <i>
            <font-awesome-icon :icon="['fas', 'user']" />
          </i>
        </div>
        <div class="alert-text">
          <span class="font-weight-bold d-block">No permission groups found</span>
          <span class="d-block">
            You don't have the permission to create users.
          </span>
        </div>
      </div>
    </template>
    <!-- end::Alert -->
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Form from '@/mixins/form';
import { errorComponentMixin } from '@vedicium/core-vue';
import errorTranslationAlert from '@/components/errors/translation.alert.vue';

import User from '@/libs/classes/user';
import { CompanyClass as Company } from '@vedicium/core-vue/build/modules/company';

export default {
  mixins: [Form, errorComponentMixin],
  components: {
    errorTranslationAlert,
  },
  props: {
    document: {
      type: User,
      default: null,
    },
    company: {
      type: Company,
      default: null,
    },
  },
  data () {
    return {
      permissionGroups: [],
    };
  },
  computed: {
    companyPermissionID: {
      get () {
        const idx = this.getCompanyPermissionIndex(this.document, this.company);
        return (idx !== -1 ? this.document.company_permissions[idx].permission_id : null);
      },
      set (permissionID = null) {
        if (!permissionID) {
          return;
        }

        // Add / edit permission_guid
        const idx = this.getCompanyPermissionIndex(this.document, this.company);
        if (idx === -1) {
          this.document.company_permissions.push({ company_guid: this.company._meta.guid, permission_id: permissionID });
        } else {
          this.document.company_permissions[idx].permission_id = permissionID;
        }
      },
    },
  },
  validations: {
    companyPermissionID: {
      required,
    },
  },

  async mounted () {
    this.$errors.clear({ component: this });

    try {
      this.$set(this, 'permissionGroups', await this.$ws.get(`${Company.uri}/permissions`));
    } catch (e) {
      console.error(e);
      this.$errors.handle(e, { component: this, ui_element: false });
      return;
    } finally {
      this.$set(this, 'isMounted', true);
    }
  },

  methods: {
    getCompanyPermissionIndex (user = null, company = null) {
      if (!user || !company) {
        throw new Error('User and/or company not found');
      }

      const companyPermissionItem = user.getCompanyPermission(company._meta.guid);
      return user.company_permissions.indexOf(companyPermissionItem);
    },
  },
};
</script>
